import React from "react";

const ThankYou = () => {
  return (
    <div className="grid">
      <div className="max-w-md w-full">
        <div className="p-4 items-center">
          <h2 className="ml-2 text-xl font-bold">Thank you for donating.</h2>
          <p>
            If you are not directed back to the app in 5 seconds, please press
            the button below
          </p>
          <button
            type="button"
            onClick={() => window.replace("givestar://payment/success")}
            className="pay-button w-full text-black bg-yellow focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2">
            RETURN TO APP
          </button>
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
