const getLoadScript = (src) =>
  new Promise((resolve, reject) => {
    const scriptElem = Object.assign(document.createElement("script"), {
      type: "text/javascript",
      defer: true,
      src,
      onerror: (e) => {
        reject(e);
      },
    });
    scriptElem.onload = () => {
      resolve();
    };
    document.body.appendChild(scriptElem);
  });

export default getLoadScript;
