import React from "react";
import GiveStarStar from "../assets/give-star-star.png";

const Header = () => {
  return (
    <div className="bg-yellow grid">
      <div className="max-w-md justify-self-center w-full">
        <div className="p-4 flex items-center">
          <img src={GiveStarStar} className="h-12" alt="GiveStar Icon" />
          <h1 className="ml-2 text-3xl font-bold font-grotesk">Donate</h1>
        </div>
      </div>
    </div>
  );
};

export default Header;
