import React from "react";
import CharityLogo from "./CharityLogo.js";

const CharityTile = ({ charity }) => {
  return (
    <div className="flex justify-center flex-col max-w-80 w-1/5">
      <CharityLogo charity={charity} />
      <p className="line-clamp-1 text-xs mt-1 font-grotesk w-3/4">{charity.charityName}</p>
    </div>
  );
};

export default CharityTile;
