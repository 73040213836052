import React, { useEffect, useState } from "react";
import { getCampaignDetails } from "./api/getCampaignAPI";
import { getCurrency } from "./api/getCurrencyAPI";
import { Spinner } from "react-activity";

import Header from "./components/Header";
import DonationCampaignDetails from "./components/DonationCampaignDetails";
import PaymentDetails from "./components/PaymentDetails";

import "react-activity/dist/library.css";
import "./App.css";
import getLoadScript from "./helpers/getLoadScript";

const App = () => {
  const [safeCharge, setSafeCharge] = useState(null);
  const [sessionToken, setSessionToken] = useState(null);
  const [donationId, setDonationId] = useState(null);
  const [emailAddress, setEmail] = useState(null);
  const [quickCode, setQuickCode] = useState(null);
  const [currencyId, setCurrencyId] = useState(null);
  const [merchantSiteId, setMerchantSiteId] = useState(null);
  const [merchantId, setMerchantId] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [totalToPay, setTotalToPay] = useState(null);
  const [campaignDetails, setCampaignDetails] = useState(null);
  const [showApplePay, setShowApplePay] = useState(null);
  const [showGooglePay, setShowGooglePay] = useState(null);

  const queryString = window.location.search;

  useEffect(() => {
    const urlParams = new URLSearchParams(queryString);
    setSessionToken(urlParams.get("sessionToken"));
    setEmail(urlParams.get("emailAddress"));
    setMerchantId(urlParams.get("merchantId"));
    setMerchantSiteId(urlParams.get("merchantSiteId"));
    setDonationId(urlParams.get("donationId"));
    setQuickCode(urlParams.get("quickCode"));
    setTotalToPay(urlParams.get("totalToPay"));
    setCurrencyId(urlParams.get("paymentCurrency"));
  }, [sessionToken, queryString]);

  useEffect(() => {
    if (merchantId && merchantSiteId) {
      getLoadScript(
        "https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js"
      ).then(() => {
        setSafeCharge(
          window.SafeCharge({
            env: "prod",
            merchantId,
            merchantSiteId,
          })
        );
      });
    }
  }, [merchantId, merchantSiteId]);

  useEffect(() => {
    let mounted = true;
    if (quickCode) {
      getCampaignDetails(quickCode).then((items) => {
        if (mounted) {
          setCampaignDetails(items);
        }
      });

      return () => (mounted = false);
    }
  }, [quickCode]);

  useEffect(() => {
    let mounted = true;
    if (currencyId) {
      getCurrency().then((currencyList) => {
        if (mounted) {
          let currency = currencyList.find((item) => item.id === currencyId);
          setCurrency(currency);
        }
      });
      return () => (mounted = false);
    }
  }, [currencyId]);

  useEffect(() => {
    setShowGooglePay(false);
  }, [showGooglePay]);

  useEffect(() => {
    if (window.ApplePaySession) {
      var merchantIdentifier = "merchant.givestar.com";
      var promise =
        window.ApplePaySession.canMakePaymentsWithActiveCard(
          merchantIdentifier
        );
      promise.then(function (canMakePayments) {
        setShowApplePay(canMakePayments);
      });
    }
  }, [setShowApplePay]);

  return (
    <div className="App font-grotesk">
      <Header />

      {!(safeCharge && campaignDetails && currency) ? (
        <div className="flex justify-center mt-10">
          <div>
            <Spinner color="#FED500" size={25} speed={1} animating={true} />
          </div>
        </div>
      ) : (
        <div className="flex justify-center flex-col">
          <DonationCampaignDetails campaignDetails={campaignDetails} />
          <PaymentDetails
            currency={currency}
            totalToPay={totalToPay}
            safeCharge={safeCharge}
            donationId={donationId}
            quickCode={quickCode}
            emailAddress={emailAddress}
            sessionToken={sessionToken}
            merchantId={merchantId}
            merchantSiteId={merchantSiteId}
            showApplePay={showApplePay}
            showGooglePay={showGooglePay}
          />
        </div>
      )}
    </div>
  );
};

export default App;
