import React from "react";
import CharityTile from "./CharityTile.js";

const DonationCampaignDetails = ({ campaignDetails }) => {
  return (
    <div className="bg-green grid">
      <div className="max-w-md justify-self-center w-full p-3">
        <h2 className="ml-2 text-xl font-bold font-grotesk">
          Donate to {campaignDetails.name}
        </h2>
        <p className="ml-2 font-grotesk">Fundraising on behalf of:</p>
        <div className="flex justify-center gap-2 mt-1">
          {campaignDetails.campaignCharities.map((charity, i) => {
            return <CharityTile key={i} charity={charity} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default DonationCampaignDetails;
