import React from "react";
import { ApplePayButton } from "react-apple-pay-button";

const ApplePay = ({
  safeCharge,
  sessionToken,
  merchantId,
  merchantSiteId,
  totalToPay,
  donationId,
}) => {
  const createPayment = (e) => {
    try {
      safeCharge.createApplePayPayment(
        {
          sessionToken,
          clientUniqueId: donationId,
          clientRequestId: donationId,
          merchantId,
          env: "prod",
          merchantSiteId,
          applicationData: "RGVtbyBzaXRl", //may remove?
          countryCode: "GB",
          currencyCode: "GBP",
          total: {
            label: "GiveStar",
            amount: totalToPay,
          },
        },
        (resp) => {
          if (resp.result === "APPROVED") {
            window.location.replace("givestar://payment/success");
          }
        }
      );
    } catch (e) {
      console.log("Error", e);
    }
  };

  return (
    <div className="max-w-16">
      <ApplePayButton onClick={createPayment} theme="dark">
        Donate with
      </ApplePayButton>
    </div>
  );
};

export default ApplePay;
