import React, { memo, useEffect, useState } from "react";
import ApplePay from "./ApplePay.js";
import ThankYou from "./ThankYou.js";
// import GooglePay from "./GooglePay.js";

const PaymentDetails = memo(
  ({
    safeCharge,
    donationId,
    emailAddress,
    sessionToken,
    merchantId,
    merchantSiteId,
    showApplePay,
    totalToPay,
    quickCode,
  }) => {
    const [cardHolderName, setCardHolderName] = useState(null);
    const [cardN, setCardN] = useState(null);
    const [cardNumberError, setCardNumberError] = useState(false);
    const [cVVError, setCVVError] = useState(false);
    const [expiryDateError, setExpiryDateError] = useState(false);
    const [cardHolderNameError, setCardHolderNameError] = useState(false);
    const [processingPayment, setProcessingPayment] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);

    // const [showGooglePay, setShowGooglePay] = useState(false);
    // const showGooglePay = true;

    useEffect(() => {
      if (safeCharge && !showThankYou) {
        const safeChargeFields = safeCharge.fields({});

        const cardNumber = safeChargeFields.create("ccNumber", {});

        cardNumber.attach("#card-number");

        const cardExpiry = safeChargeFields.create("ccExpiration", {});

        cardExpiry.attach("#card-expiry");

        const cardCvc = safeChargeFields.create("ccCvc", {});

        cardCvc.attach("#card-cvc");
        setCardN(cardNumber);
      }
    }, [safeCharge, showThankYou]);

    const createPayment = (e) => {
      setProcessingPayment(true);
      if (cardN) {
        try {
          safeCharge.createPayment(
            {
              sessionToken: sessionToken,
              clientUniqueId: donationId,
              clientRequestId: donationId,
              merchantId,
              merchantSiteId,
              cardHolderName: cardHolderName,
              paymentOption: cardN,
            },
            (resp) => {
              console.log(resp);
              if (resp.result === "ERROR") {
                if (resp.errorDescription === "incorrect_card_number") {
                  setCardNumberError(true);
                } else if (resp.errCode === 1019) {
                  if (resp.errorDescription === "cardData.CVV is invalid") {
                    setCVVError(true);
                  } else {
                    setExpiryDateError(true);
                  }
                } else if (resp.errCode === 1136) {
                  setCardHolderNameError(true);
                }
              } else if (resp.result === "APPROVED") {
                setShowThankYou(true);
                window.location.replace("givestar://payment/success");
              } else {
                setProcessingPayment(false);
                window.location.replace(
                  `givestar://payment/false?errorDescription=${resp.errorDescription}&result=${resp.result}&errCode=${resp.errorCode}&transactionId=${resp.transactionId}`
                );
              }
              setProcessingPayment(false);
            }
          );
        } catch (e) {
          setProcessingPayment(false);
        }
      }
    };

    return (
      <div className="flex justify-center w-full">
        {showThankYou ? (
          <ThankYou />
        ) : (
          <div className="mt-2 p-4 m-5 max-w-md flex flex-col justify-center flex-col w-full">
            {showApplePay && (
              <>
                <h2 className="ml-2 text-xl font-bold">Apple Pay</h2>
                <div className="item mt-4 w-64  mx-auto">
                  <ApplePay
                    safeCharge={safeCharge}
                    merchantId={merchantId}
                    merchantSiteId={merchantSiteId}
                    sessionToken={sessionToken}
                    totalToPay={totalToPay}
                    donationId={donationId}
                    emailAddress={emailAddress}
                  />
                </div>
                <p className="m-2">or</p>
              </>
            )}

            {/* {showGooglePay && (
            <>
              <h2 className="ml-2 text-xl font-bold">Apple Pay</h2>
              <GooglePay
                safeCharge={safeCharge}
                merchantId={merchantId}
                merchantSiteId={merchantSiteId}
                sessionToken={sessionToken}
                totalToPay={totalToPay}
                donationId={donationId}
                emailAddress={emailAddress}
              />
            </>
          )} */}

            {/* {(showApplePay || showGooglePay) && <p className="m-2">or</p>} */}

            <h2 className="ml-2 text-xl font-bold">Card Payment</h2>
            <p className="ml-2">
              Please enter your card details and press 'donate' to make your
              donation.
            </p>
            <>
              <div className="col-span-3 item gap-6 mt-4">
                <input
                  className={`input border text-gray-900 text-sm rounded-lg block w-full p-2.5
                ${
                  cardHolderNameError
                    ? "focus:ring-red-500 focus:border-red-500 bg-red-50"
                    : "bg-gray-50 focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                }`}
                  id="cardHolderName"
                  placeholder="Cardholder Name"
                  type="text"
                  onChange={(e) => setCardHolderName(e.target.value)}
                />
              </div>

              <div className="item item gap-6 mt-4">
                <div
                  id="card-number"
                  className={`input border text-sm rounded-lg block w-full p-2.5
                ${
                  cardNumberError
                    ? "focus:ring-red-500 focus:border-red-500 bg-red-50"
                    : "bg-gray-50 focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                }`}
                />
              </div>

              <div className="grid grid-cols-2 grid-flow-col gap-4">
                <div className="item mt-4">
                  <label
                    htmlFor="card-expiry"
                    className="text-left block mb-2 text-sm font-medium text-gray-900">
                    Expiry Date
                  </label>
                  <div
                    id="card-expiry"
                    className={`input border text-sm rounded-lg block w-full p-2.5
                ${
                  expiryDateError
                    ? "focus:ring-red-500 focus:border-red-500 bg-red-50"
                    : "bg-gray-50 focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                }`}
                  />
                </div>

                <div className="item mt-4">
                  <label
                    htmlFor="card-cvc"
                    className="text-left block mb-2 text-sm font-medium text-gray-900">
                    CVV
                  </label>
                  <div
                    id="card-cvc"
                    className={`input border text-sm rounded-lg block w-full p-2.5
                ${
                  cVVError
                    ? "focus:ring-red-500 focus:border-red-500 bg-red-50"
                    : "bg-gray-50 focus:ring-blue-500 focus:border-blue-500 border-gray-300"
                }`}
                  />
                </div>
              </div>
              <div className="pt-5">
                <h2 className="ml-2 text-xl font-bold">
                  Total to Pay: £{totalToPay}
                </h2>
              </div>

              <div className="item mt-4 w-full">
                <button
                  type="button"
                  disabled={processingPayment}
                  onClick={createPayment}
                  className="pay-button w-full font-bold text-black bg-yellow focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2">
                  {processingPayment ? "PROCESSING..." : "DONATE SECURELY"}
                </button>
              </div>
            </>
          </div>
        )}
      </div>
    );
  }
);

export default PaymentDetails;
